// @flow
import React from 'react';
import styled from 'styled-components';

import ClientLogos1 from '../assets/clientLogos1.svg';
import ClientLogos2 from '../assets/clientLogos2.svg';
import Col from '../components/Col';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Markdown from '../components/Markdown';
import Row from '../components/Row';
import Section from '../components/Section';
import strings from '../config/strings.json';
import {mediaQueries} from '../theme';
import {colors} from '../theme/colors';
import {LocationType} from '../types';
import {rhythm} from '../utils/typography';

const IMAGE_1 = 'https://www.datocms-assets.com/639/1649708279-project-1.png';
const IMAGE_2 = 'https://www.datocms-assets.com/639/1649708296-project-2.png';
const IMAGE_3 = 'https://www.datocms-assets.com/639/1649708308-project-3.png';

const firstImageBlurhash =
  ':5EfWs~q^+IAD$ofRjx]00xuxu%MRiIUj[t7xZD%9Zxv-;oeofRj%NRkM_9Fxu?bofWA9FIU%M%MM{RjofbHITM_WC-;xuM{M{M{-;xuIU9Ft7xuj]jZxukCtRRjITM{xux]';

const IMAGE_MODIFIERS = {
  width: 1536,
  height: 850,
};

const StyledSection = styled(Section)`
  * {
    color: ${colors.black80};
  }

  p {
    max-width: 666px;
  }
`;

const StyledTitle = styled.h2`
  max-width: 560px;
  color: ${colors.blackHard};
  margin-top: -${rhythm(1 / 4)};
  margin-bottom: ${rhythm(1)};
  ${mediaQueries.MIN_LG} {
    margin-bottom: ${rhythm(4 / 3)};
  }

  ${mediaQueries.MAX_SM} {
    font-size: 1.6125rem;
  }
`;

const StyledTestimonial = styled.div`
  border-left: 1px solid ${colors.black20};
  padding-left: ${rhythm(1 / 3)};
  ${mediaQueries.MIN_MD} {
    padding-left: ${rhythm(1)};
  }

  @media and (min-resolution: 144dpi), (-webkit-min-device-pixel-ratio: 1.5) {
    margin-bottom: ${rhythm(1 / 2)};
  }

  p {
    max-width: 620px;
  }

  p:nth-of-type(4) {
    margin-bottom: 0;
  }
`;

const ImageSliderWrapper = styled.div`
  position: relative;
  width: 100%;

  height: calc(100vw * 0.56);
  max-height: 920px;
  margin-top: ${rhythm(3 / 4)};

  ${mediaQueries.SM_ONLY} {
    margin-bottom: ${rhythm(1 / 2)};
  }

  figure {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;

    animation-name: crossFade;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-iteration-count: infinite;
    animation-duration: 12s;

    &:nth-of-type(1) {
      animation-delay: 2s;
      z-index: 999;
    }
    &:nth-of-type(2) {
      animation-delay: 6s;
      z-index: 998;
    }
    &:nth-of-type(3) {
      animation-delay: 10s;
      z-index: 997;
    }
  }

  @keyframes crossFade {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

class PageHome extends React.Component {
  render() {
    const {location} = this.props;
    const {pathname: path} = location;
    const {header, content, pageMeta} = strings.pages.home;

    return (
      <Layout path={path} pageMeta={{strings: pageMeta}} isHome>
        <StyledSection>
          <Row justifyCenter>
            <Col md={6} lg={5} xl={4} className="content">
              <StyledTitle data-testid="title">{header}</StyledTitle>
              <Markdown content={content} />
            </Col>
          </Row>
        </StyledSection>
        <ImageSliderWrapper>
          <Image
            imageSource={{url: IMAGE_1, blurhash: firstImageBlurhash}}
            imageModifiers={IMAGE_MODIFIERS}
          />
          <Image
            imageSource={{url: IMAGE_2}}
            imageModifiers={IMAGE_MODIFIERS}
          />
          <Image
            imageSource={{url: IMAGE_3}}
            imageModifiers={IMAGE_MODIFIERS}
          />
        </ImageSliderWrapper>
      </Layout>
    );
  }
}

PageHome.propTypes = {
  location: LocationType,
};

export default PageHome;
